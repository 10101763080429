import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useLoaderData, defer, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AsanaTicketSelect from "~/blocks/asana";
import { GdriveCredsGuard } from "~/blocks/gdrive/credentials";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function applicationsToOptions(applications) {
  return applications.map((application) => ({
    label: application.code,
    value: application.id,
  }));
}

function PlayableConceptCreationLayout({ applications }) {
  return (
    <>
      <Row>
        <p className="mt-4">
          Please provide the following data to add and set up a new playable (*
          required fields):
        </p>
      </Row>
      <PlayableConceptCreator applications={applications} />
    </>
  );
}

function PlayableConceptCreatePage() {
  const { applications } = useLoaderData();

  return (
    <>
      <PageHeader />
      <Container>
        <GdriveCredsGuard>
          <PlayableConceptCreationLayout applications={applications} />
        </GdriveCredsGuard>
      </Container>
    </>
  );
}

PlayableConceptCreatePage.loader = async function loader() {
  const [applicationsResponse] = await Promise.all([axios("applications")]);

  return defer({
    applications: applicationsResponse.data,
  });
};

/* eslint-disable no-template-curly-in-string */
const playableConceptValidationSchema = Yup.object().shape({
  application: Yup.number().required("Required"),
  theme: Yup.string().min(2).max(255).required("Required"),
});
/* eslint-enable no-template-curly-in-string */

function PlayableConceptCreator({ applications }) {
  const navigate = useNavigate();
  const [newPlayableConcept, setNewPlayableConcept] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creationStatus, setCreationStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const onSubmit = (values) => {
    setCreationStatus(null);
    setIsLoading(true);
    return axios({
      method: "POST",
      url: "playable-concepts/",
      data: values,
    })
      .then((response) => {
        setNewPlayableConcept(response.data);
        setCreationStatus("success");
        setIsLoading(false);
        navigate(`/playables/concepts/${response.data.id}/assets`);
      })
      .catch((errorResponse) => {
        setCreationStatus("error");
        const message = errorResponse.response.data.non_field_errors
          ? errorResponse.response.data.non_field_errors[0]
          : errorResponse.response.data;
        setErrorMessage(message);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          form={false}
          initialValues={{
            application: "",
            theme: "",
            asana_ticket_id: "",
          }}
          validationSchema={playableConceptValidationSchema}
          syncSubmit
          onSubmit={onSubmit}
        >
          <AsanaTicketSelect
            isLoading={isLoading}
            creationStatus={creationStatus}
            assetType="playable"
          />
          <Row className="mb-3 pb-3">
            <Col xs={2}>Application:*</Col>
            <Col xs={5}>
              <Form.Select
                name="application"
                options={applicationsToOptions(applications)}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="application" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Theme:*</Col>
            <Col xs={5}>
              <Form.Control
                name="theme"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
              />
              <Form.Control.ErrorFeedback name="concept_name" />
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={isLoading || creationStatus === "success"}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && (
        <Row>
          <Col xs={{ offset: 6, span: 1 }}>
            <FontAwesomeIcon
              className="fa fa-spin fa-2xl"
              icon={faSpinner}
              color="#0d6efd"
            />
          </Col>
        </Row>
      )}
      {creationStatus === "success" && (
        <>
          <Row>
            <a
              href={newPlayableConcept.creative_folder_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Drive Link
            </a>
          </Row>
          <Row className="mb-3 mt-4">
            <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
              Playable concept added to Cactus
              <FontAwesomeIcon
                className="fa fa-solid fa-2xl ms-3"
                icon={faCircleCheck}
                color="green"
              />
            </Col>
          </Row>
        </>
      )}
      {creationStatus === "error" && (
        <Row style={{ marginTop: "5rem" }} className="mb-3">
          <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
            Playable Concept not added. {errorMessage}
            <FontAwesomeIcon
              className="fa fa-solid fa-2xl ms-3"
              icon={faCircleXmark}
              color="red"
            />
          </Col>
        </Row>
      )}
      {creationStatus !== null && creationStatus !== "error" && (
        <Row>
          <Col xs={{ offset: 5, span: 2 }} style={{ textAlign: "center" }}>
            <Button href="/creatives/concepts/new">+ New Playable</Button>
          </Col>
        </Row>
      )}
    </>
  );
}

export default PlayableConceptCreatePage;
