import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Typeahead as BsTypeahead } from "react-bootstrap-typeahead";

import { useStatusValid } from "./error";

function Typeahead({ name, ...props }) {
  const [{ onBlur, value }, meta, { setValue }] = useField({
    ...props,
    multiple: props.isMulti,
    name,
  });
  const isStatusValid = useStatusValid(name);
  const isInvalid = Boolean(meta.touched && (!isStatusValid || meta.error));
  // Convert Formik's value to an array for compatibility with react-bootstrap-typeahead.
  // Typeahead expects the 'selected' prop to always be an array, even in single-select mode.
  let selected = [];
  if (Array.isArray(value)) {
    selected = value;
  } else if (value) {
    selected = [value];
  }
  return (
    <DetachedTypeahead
      {...props}
      id={name}
      value={value}
      selected={selected}
      onChange={setValue}
      onBlur={() => onBlur({ target: { name } })}
      isInvalid={isInvalid}
    />
  );
}

Typeahead.propTypes = {
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  styles: PropTypes.shape({}),
};

Typeahead.defaultProps = {
  isMulti: false,
  styles: null,
};

const DetachedTypeahead = React.forwardRef(
  ({ selected, isMulti, onChange, options, onPaste, ...props }, ref) => {
    const handleSelectChange = React.useCallback(
      (selection, action) => {
        if (selection.length === 0) {
          return;
        }
        onChange(selection[0], action);
      },
      [onChange]
    );
    const handleInputChange = React.useCallback(
      (text) => {
        onChange(text);
      },
      [onChange]
    );

    return (
      <BsTypeahead
        ref={ref}
        isMulti={isMulti}
        options={options}
        selected={selected}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        onPaste={onPaste}
        {...props}
      />
    );
  }
);

DetachedTypeahead.defaultProps = {
  isMulti: false,
};

export { DetachedTypeahead };
export default Typeahead;
