import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { cachedAxios, useSearchParams } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/network-launches/filter-choices");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();

  const initialValues = {
    application: state.application || null,
    network: state.network || null,
    status: state.status || null,
  };

  const onChange = (values) => {
    setState({
      application: values.application || null,
      network: values.network || null,
      status: values.status || null,
      page: 1,
    });
  };
  return (
    <NetworkLaunchesFiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function NetworkLaunchesFiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        <FilterCol label="App" md={2}>
          <Form.Select name="application" options={options.application} />
        </FilterCol>
        <FilterCol label="Network" md={2}>
          <Form.Select name="network" options={options.network} />
        </FilterCol>
        <FilterCol label="Status" md={2}>
          <Form.Select name="status" options={options.status} />
        </FilterCol>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Filter" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm };
