import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { useAlert } from "~/base/alerts";
import { axios } from "~/utils";

function RetryButton({
  endpoint,
  buttonText = "Retry",
  buttonVariant = "success",
  buttonSize = "sm",
  defaultRetryAfter = 30,
}) {
  const addAlert = useAlert();
  const [isDisabled, setIsDisabled] = useState(false);

  const retry = () => {
    axios({
      method: "POST",
      url: endpoint,
    })
      .then(() => {
        addAlert("Successfully scheduled a retry.", "info");
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          const retryAfter =
            error.response.headers["retry-after"] || defaultRetryAfter;
          if (retryAfter) {
            const retryAfterSeconds = parseInt(retryAfter, 10);
            if (!Number.isNaN(retryAfterSeconds)) {
              setIsDisabled(true);
              setTimeout(() => {
                setIsDisabled(false);
              }, retryAfterSeconds * 1000);
            }
          }
          const retryAfterTime = retryAfter ? `${retryAfter} secs` : "a moment";
          addAlert(
            `Rate limit reached. Please wait ${retryAfterTime} before retrying.`,
            "warning"
          );
        } else {
          addAlert("Failed to retry", "error");
        }
      });
  };

  return (
    <Button
      variant={buttonVariant}
      size={buttonSize}
      onClick={retry}
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
}

export default RetryButton;
