import React from "react";
import { Row, Col } from "react-bootstrap";

import { ResetButton, FilterCol } from "~/components/filters";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { useFilters } from "~/components/page-header";
import { useSearchParams, cachedAxios } from "~/utils";

async function filtersLoader() {
  const response = await cachedAxios("/creative-concepts/filter_choices");
  return response.data;
}

function PageFiltersForm({ options, filterNames }) {
  const { state, setState } = useSearchParams();
  const { setFilterOpened } = useFilters(filterNames);

  const initialValues = {
    application: state.application || "",
    creative_number: state.creative_number || "",
    concept_name: state.concept_name || "",
    intro: state.intro || "",
    identifier: state.identifier || "",
    agency: state.agency || "",
    graphics_type: state.graphics_type || "",
    gameplay_type: state.gameplay_type || "",
    creative_type: state.creative_type || "",
  };

  const onChange = (values) => {
    setState({
      application: values.application || null,
      creative_number: values.creative_number || null,
      intro: values.intro || null,
      concept_name: values.concept_name || null,
      identifier: values.identifier || null,
      agency: values.agency || null,
      graphics_type: values.graphics_type || null,
      gameplay_type: values.gameplay_type || null,
      creative_type: values.creative_type || null,
      page: 1,
    });
    setFilterOpened(false);
  };

  const filterConfig = {
    application: {
      label: "App",
      component: (
        <Form.Select name="application" options={options.application} />
      ),
      customParams: { md: 1 },
    },
    creative_number: {
      label: "Creative #",
      component: (
        <Form.Select name="creative_number" options={options.creative_number} />
      ),
      customParams: { md: 1 },
    },
    intro: {
      label: "Intro",
      component: <Form.Select name="intro" options={options.intro} />,
      customParams: { md: 2 },
    },
    identifier: {
      label: "Identifier",
      component: <Form.Select name="identifier" options={options.identifier} />,
      customParams: { md: 2 },
    },
    agency: {
      label: "Agency",
      component: <Form.Select name="agency" options={options.agency} />,
      customParams: { md: 2 },
    },
    concept_name: {
      label: "Name",
      component: <Form.Control name="concept_name" />,
      customParams: { md: 2 },
    },
    graphics_type: {
      label: "2D / 3D",
      component: (
        <Form.Select name="graphics_type" options={options.graphics_type} />
      ),
      customParams: { md: 2 },
    },
    gameplay_type: {
      label: "Type",
      component: (
        <Form.Select name="gameplay_type" options={options.gameplay_type} />
      ),
      customParams: { md: 2 },
    },
    creative_type: {
      label: "Creative Type",
      component: (
        <Form.Select name="creative_type" options={options.creative_type} />
      ),
      customParams: { md: 2 },
    },
  };

  const selectedFilters = filterNames.map(
    (filterName) => filterConfig[filterName]
  );
  return (
    <ConceptsFiltersForm
      filters={selectedFilters}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function ConceptsFiltersForm({ filters, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3 justify-content-md-center">
        {filters.map(({ label, component, customParams }) => (
          <FilterCol key={label} label={label} md={customParams.md}>
            {component}
          </FilterCol>
        ))}
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-end" md={12} sm={10}>
          <Col xs="auto" className="me-2">
            <ResetButton />
          </Col>
          <Col xs="auto">
            <SubmitButton icon={false} title="Filter" />
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export { filtersLoader, PageFiltersForm, ConceptsFiltersForm };
