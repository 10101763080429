import React from "react";
import { Container, Row } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import {
  ConceptCreator,
  ZephyrConceptCreator,
} from "~/blocks/creatives/concepts/concept-creator";
import { GdriveCredsGuard } from "~/blocks/gdrive/credentials";
import PageHeader from "~/components/page-header";
import { isZephyrEnv } from "~/constants";
import { axios } from "~/utils";

function ConceptCreationLayout({ isZephyr, data }) {
  let layout;
  if (isZephyr) {
    layout = (
      <ZephyrConceptCreator
        applications={data.applications}
        conceptNames={data.createConceptOptions.concept_name}
        gameplayTypes={data.createConceptOptions.gameplay_types}
        graphicsTypes={data.createConceptOptions.graphics_types}
      />
    );
  } else {
    layout = (
      <ConceptCreator
        applications={data.applications}
        adTypes={data.adTypes}
        conceptNames={data.createConceptOptions.concept_name}
        intros={data.createConceptOptions.intro}
        identifiers={data.createConceptOptions.identifier}
        creativeTypes={data.creativeTypes}
      />
    );
  }
  return (
    <>
      <Row>
        <p className="mt-4">
          Please provide the following data to add and setup a new creative (*
          required fields):
        </p>
      </Row>
      {layout}
    </>
  );
}

function CreativesConceptCreatePage() {
  const loaderData = useLoaderData();
  return (
    <>
      <PageHeader />
      <Container>
        <GdriveCredsGuard>
          <ConceptCreationLayout isZephyr={isZephyrEnv()} data={loaderData} />
        </GdriveCredsGuard>
      </Container>
    </>
  );
}

CreativesConceptCreatePage.loader = async function loader() {
  const [applicationsResponse] = await Promise.all([axios("applications")]);
  const [adTypesResponse] = await Promise.all([axios("ad-types")]);
  const [creativeTypes] = await Promise.all([axios("creative-types")]);
  const [createConceptOptionsResponse] = await Promise.all([
    axios("creative-concepts/create-concept-options"),
  ]);

  return defer({
    applications: applicationsResponse.data,
    adTypes: adTypesResponse.data,
    createConceptOptions: createConceptOptionsResponse.data,
    creativeTypes: creativeTypes.data,
  });
};

export default CreativesConceptCreatePage;
