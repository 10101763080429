const defaultAlertTimeout = 5000;

/* eslint-disable no-underscore-dangle */
function getEnvironmentConfig() {
  return window && window._environmentConfig ? window._environmentConfig : {};
}
/* eslint-enable no-underscore-dangle */

function getEnvironmentName() {
  const environmentConfig = getEnvironmentConfig();
  const longName = environmentConfig.environment.toUpperCase() || "UNKNOWN";
  const shortName = environmentConfig.environment_short.toUpperCase() || "UNKN";
  return [shortName, longName];
}

function getBaseURL() {
  const environmentConfig = getEnvironmentConfig();
  const baseURL = environmentConfig.base_url || "/crops-api";
  return baseURL;
}

function getGdriveCredentials() {
  const environmentConfig = getEnvironmentConfig();
  return {
    client_id: environmentConfig.gdrive_client_id,
    redirect_uri: environmentConfig.gdrive_redirect_uri,
    scope: "https://www.googleapis.com/auth/drive",
  };
}

function getYoutubeCredentials() {
  const environmentConfig = getEnvironmentConfig();
  return {
    client_id: environmentConfig.youtube_client_id,
    redirect_uri: environmentConfig.youtube_redirect_uri,
    scope: "https://www.googleapis.com/auth/youtube",
  };
}

function getGoogleAdsCredentials() {
  const environmentConfig = getEnvironmentConfig();
  return {
    client_id: environmentConfig.google_ads_client_id,
    redirect_uri: environmentConfig.google_ads_redirect_uri,
    scope: " https://www.googleapis.com/auth/adwords",
  };
}

function isZephyrEnv() {
  return getEnvironmentConfig().is_zephyr_env;
}

export {
  getEnvironmentName,
  defaultAlertTimeout,
  getBaseURL,
  getGdriveCredentials,
  getYoutubeCredentials,
  getGoogleAdsCredentials,
  isZephyrEnv,
};
