import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import { axios } from "~/utils";
import { mapToOptions } from "~/utils/options";

function AsanaTicketSelect({ isLoading, creationStatus, assetType }) {
  const [ticketsOptions, setTicketOptions] = React.useState([]);
  const [tickets, setTickets] = React.useState([]);
  const addAlert = useAlert();
  React.useEffect(() => {
    axios({
      method: "GET",
      url: `asana-tickets/?asset_type=${assetType}`,
    })
      .then((response) => {
        setTickets(response.data);
        setTicketOptions(mapToOptions(response.data, "id", "name"));
      })
      .catch(() => {
        addAlert(
          "Error uploading Asana tickets. Please try to refresh a page or reach out to the MAD Products Team",
          "error"
        );
      });
  }, [assetType, addAlert]);
  const { values, setFieldValue } = useFormikContext();
  const updateCustomFields = React.useCallback(
    (ticketId) => {
      const selectedTicket = tickets.find((ticket) => ticket.id === ticketId);
      if (selectedTicket) {
        Object.entries(selectedTicket.custom_fields).forEach(
          ([field, value]) => {
            setFieldValue(field, value);
          }
        );
      }
    },
    [tickets, setFieldValue]
  );
  React.useEffect(() => {
    if (values.asana_ticket_id) {
      updateCustomFields(values.asana_ticket_id);
    }
  }, [values.asana_ticket_id, updateCustomFields]);
  return (
    <Row className="mb-3 pb-3">
      <Col xs={2}>Asana ticket:</Col>
      <Col xs={5}>
        <Form.Select
          name="asana_ticket_id"
          options={ticketsOptions}
          disabled={isLoading || creationStatus === "success"}
          isClearable
        />
        <Form.Control.ErrorFeedback name="asana_ticket_id" />
      </Col>
    </Row>
  );
}

export default AsanaTicketSelect;
