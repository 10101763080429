import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AsanaTicketSelect from "~/blocks/asana";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios } from "~/utils";
import { mapToOptions } from "~/utils/options";

function addEmptyOption(options) {
  return [{ label: "-", value: null }, ...options];
}

function useConceptCreation(submitUrl) {
  const navigate = useNavigate();
  const [newConcept, setNewConcept] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creationStatus, setCreationStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  const onSubmit = (values) => {
    setCreationStatus(null);
    setIsLoading(true);
    return axios({
      method: "POST",
      url: submitUrl,
      data: values,
    })
      .then((response) => {
        setNewConcept(response.data);
        setCreationStatus("success");
        setIsLoading(false);
        navigate(`/creatives/concepts/${response.data.id}/assets`);
      })
      .catch((errorResponse) => {
        setCreationStatus("error");
        const message = errorResponse.response.data.non_field_errors
          ? errorResponse.response.data.non_field_errors[0]
          : errorResponse.response.data;
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return {
    newConcept,
    isLoading,
    creationStatus,
    errorMessage,
    onSubmit,
  };
}

function LoadingIndicator() {
  return (
    <Row>
      <Col xs={{ offset: 6, span: 1 }}>
        <FontAwesomeIcon
          className="fa fa-spin fa-2xl"
          icon={faSpinner}
          color="#0d6efd"
        />
      </Col>
    </Row>
  );
}

function StatusMessage({ status, message, link }) {
  if (status === "success") {
    return (
      <>
        <Row>
          <a href={link} target="_blank" rel="noopener noreferrer">
            Google Drive Link
          </a>
        </Row>
        <Row className="mb-3 mt-4">
          <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
            Creative added to Cactus
            <FontAwesomeIcon
              className="fa fa-solid fa-2xl ms-3"
              icon={faCircleCheck}
              color="green"
            />
          </Col>
        </Row>
      </>
    );
  }
  if (status === "error") {
    return (
      <Row style={{ marginTop: "5rem" }} className="mb-3">
        <Col xs={12} style={{ fontSize: "large", textAlign: "center" }}>
          Creative not added. {message}
          <FontAwesomeIcon
            className="fa fa-solid fa-2xl ms-3"
            icon={faCircleXmark}
            color="red"
          />
        </Col>
      </Row>
    );
  }
  return "";
}

/* eslint-disable no-template-curly-in-string */
const conceptValidationSchema = Yup.object().shape({
  application: Yup.number().required("Required"),
  intro: Yup.string().nullable(),
  concept_name: Yup.string().min(2).max(255).required("Required"),
  identifier: Yup.string().nullable(),
  ad_type: Yup.number().nullable(),
  creative_type: Yup.number().required("Required"),
});

function ConceptCreator({
  applications,
  adTypes,
  intros,
  identifiers,
  conceptNames,
  creativeTypes,
}) {
  const { newConcept, isLoading, creationStatus, errorMessage, onSubmit } =
    useConceptCreation("creative-concepts/");

  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          form={false}
          initialValues={{
            application: null,
            creative_number: null,
            intro: null,
            concept_name: null,
            identifier: null,
            creative_type: null,
            ad_type: null,
            asana_ticket_id: null,
          }}
          validationSchema={conceptValidationSchema}
          syncSubmit
          onSubmit={onSubmit}
        >
          <Row className="mb-3 pb-3">
            <AsanaTicketSelect
              isLoading={isLoading}
              creationStatus={creationStatus}
              assetType="video"
            />
            <Col xs={2}>Application:*</Col>
            <Col xs={5}>
              <Form.Select
                name="application"
                options={mapToOptions(applications)}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="application" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Concept Name:*</Col>
            <Col xs={5}>
              <Form.Typeahead
                name="concept_name"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={conceptNames}
              />
              <Form.Control.ErrorFeedback name="concept_name" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Creative Type:*</Col>
            <Col xs={5}>
              <Form.Select
                name="creative_type"
                disabled={isLoading || creationStatus === "success"}
                options={creativeTypes}
              />
              <Form.Control.ErrorFeedback name="creative_type" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={2}>
              Intro:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Typeahead
                name="intro"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={intros}
              />
              <Form.Control.ErrorFeedback name="intro" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={2}>
              Identifier:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Typeahead
                name="identifier"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={identifiers}
              />
              <Form.Control.ErrorFeedback name="identifier" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>
              Ad Type:{" "}
              <p style={{ color: "grey", fontSize: "11px" }}>(optional)</p>
            </Col>
            <Col xs={5}>
              <Form.Select
                name="ad_type"
                disabled={isLoading || creationStatus === "success"}
                options={addEmptyOption(adTypes)}
              />
              <Form.Control.ErrorFeedback name="ad_type" />
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={isLoading || creationStatus === "success"}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && <LoadingIndicator />}
      <StatusMessage
        status={creationStatus}
        message={errorMessage}
        link={newConcept?.creative_folder_link}
      />
    </>
  );
}

const zephyrConceptValidationSchema = Yup.object().shape({
  application: Yup.number().required("Required"),
  concept_name: Yup.string().min(2).max(255).required("Required"),
  graphics_type: Yup.string().nullable(),
  gameplay_type: Yup.string().nullable(),
});

function ZephyrConceptCreator({
  applications,
  conceptNames,
  gameplayTypes,
  graphicsTypes,
}) {
  const { newConcept, isLoading, creationStatus, errorMessage, onSubmit } =
    useConceptCreation("creative-concepts/zephyr/");

  return (
    <>
      <Row className="my-4 d-flex align-items-center">
        <Form
          form={false}
          initialValues={{
            application: null,
            concept_name: null,
            graphics_type: null,
            gameplay_type: "fg",
            asana_ticket_id: null,
          }}
          validationSchema={zephyrConceptValidationSchema}
          syncSubmit
          onSubmit={onSubmit}
        >
          <AsanaTicketSelect
            isLoading={isLoading}
            creationStatus={creationStatus}
            assetType="video"
          />
          <Row className="mb-3 pb-3">
            <Col xs={2}>Application:*</Col>
            <Col xs={5}>
              <Form.Select
                name="application"
                options={mapToOptions(applications)}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="application" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Concept Name:*</Col>
            <Col xs={5}>
              <Form.Typeahead
                name="concept_name"
                disabled={isLoading || creationStatus === "success"}
                minLength={2}
                options={conceptNames}
              />
              <Form.Control.ErrorFeedback name="concept_name" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Graphics Type:*</Col>
            <Col xs={5}>
              <Form.Select
                name="graphics_type"
                options={graphicsTypes}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="graphics_type" />
            </Col>
          </Row>
          <Row className="mb-3 pb-3">
            <Col xs={2}>Gameplay Type:*</Col>
            <Col xs={5}>
              <Form.Select
                name="gameplay_type"
                options={gameplayTypes}
                disabled={isLoading || creationStatus === "success"}
              />
              <Form.Control.ErrorFeedback name="gameplay_type" />
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <SubmitButton
                className="px-4"
                icon={false}
                title="Save"
                disabled={isLoading || creationStatus === "success"}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && <LoadingIndicator />}
      <StatusMessage
        status={creationStatus}
        message={errorMessage}
        link={newConcept?.creative_folder_link}
      />
    </>
  );
}

export { ConceptCreator, ZephyrConceptCreator };
