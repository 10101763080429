import { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import {
  GdriveCredsContainer,
  GdriveCredsGuard,
} from "~/blocks/gdrive/credentials";
import { DetachedSelect as Select } from "~/components/form/select";
import PageHeader from "~/components/page-header";
import { isZephyrEnv } from "~/constants";
import { axios } from "~/utils";

import "./index.scss";

import ApplovinLaunchNetworkForm from "./applovin-launch";
import UnityLaunchNetworkForm from "./unity-launch";

function LaunchNetworkPage() {
  const { options } = useLoaderData();

  return (
    <GdriveCredsContainer>
      <GdriveCredsGuard>
        <PageHeader />
        <Container>
          <Row>
            <p className="mt-4">
              Please provide the following data to launch the videos and their
              end cards (* required fields):
            </p>
          </Row>
          <LaunchNetworkForm options={options} />
        </Container>
      </GdriveCredsGuard>
    </GdriveCredsContainer>
  );
}

LaunchNetworkPage.loader = async function loader() {
  const response = await axios("network-launches/global-options");

  return defer({
    options: response.data,
  });
};

function LaunchNetworkForm({ options }) {
  const [network, setNetwork] = useState("");
  const selectedNetwork = network !== null ? network.toLowerCase() : null;
  let NetworkLaunchForm = null;
  const isZephyr = isZephyrEnv();
  if (selectedNetwork === "applovin") {
    NetworkLaunchForm = ApplovinLaunchNetworkForm;
  } else if (selectedNetwork === "unity") {
    NetworkLaunchForm = UnityLaunchNetworkForm;
  }
  return (
    <div>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Ad Network:*</Col>
        <Col xs={5}>
          <Select
            value={network}
            onChange={setNetwork}
            options={options.networks}
          />
        </Col>
      </Row>
      {NetworkLaunchForm && (
        <NetworkLaunchForm options={options} isZephyr={isZephyr} />
      )}
    </div>
  );
}

export default LaunchNetworkPage;
