import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AuthStateContext } from "~/base/auth";
import {
  getErrorResponseText,
  StatusIcon,
  ReasonTooltip,
  generateVersionsOptions,
  getDefaultLanguage,
  getDefaultOptionFromFilename,
} from "~/blocks/upload-utils";
import { DetachedSelect } from "~/components/form/select";
import { isZephyrEnv } from "~/constants";
import { axios as internalAxios } from "~/utils";
import { getVideoMetadata } from "~/utils/video";

const baseAxiosClient = axios.create({});

function UploadAssetsModal({
  filesToUpload,
  onFileRemove,
  creative,
  onClose,
  languages,
}) {
  const [extraState, dispatch] = useReducer(
    extraStateReducer,
    filesToUpload,
    getInitialExtraState
  );
  useEffect(() => {
    filesToUpload.forEach((file) => {
      getVideoMetadata(file.file).then(({ duration, resolution }) => {
        dispatch({ type: "set-metadata", key: file.key, duration, resolution });
      });
    });
  }, [filesToUpload]);
  const [isRetryInProgress, setIsRetryInProgress] = useState(false);

  const uploadAsset = (file) => {
    dispatch({ type: "set-status", key: file.key, status: "uploading" });
    const state = extraState.find((e) => e.key === file.key);
    const formData = new FormData();
    formData.append("file_type", file.file.type);
    formData.append("original_file_name", file.file.name);
    return internalAxios({
      method: "POST",
      url: "assets/start-upload/",
      data: formData,
      timeout: 5 * 60 * 1000,
    })
      .then((startUploadResponse) => {
        const s3Data = startUploadResponse.data.presigned_data;
        const postData = new FormData();
        postData.append("key", s3Data.fields.key);
        postData.append("AWSAccessKeyId", s3Data.fields.AWSAccessKeyId);
        postData.append("policy", s3Data.fields.policy);
        postData.append("acl", s3Data.fields.acl);
        postData.append("signature", s3Data.fields.signature);
        postData.append("Content-Type", s3Data.fields["Content-Type"]);
        postData.append("file", file.file);
        baseAxiosClient({
          method: "POST",
          url: s3Data.url,
          data: postData,
          timeout: 10 * 60 * 1000,
        })
          .then(() => {
            const finishPostData = {
              original_file_name: file.file.name,
              file_type: file.file.type,
              language: state.language,
              duration: state.duration,
              resolution: state.resolution,
              file_key: s3Data.fields.key,
              version: state.version,
            };
            internalAxios({
              method: "POST",
              url: `creative-concepts/${creative.id}/finish_upload/`,
              data: finishPostData,
              timeout: 5 * 60 * 1000,
            })
              .then(() => {
                dispatch({
                  type: "set-status",
                  key: file.key,
                  status: "success",
                });
              })
              .catch((error) => {
                dispatch({
                  type: "set-status",
                  key: file.key,
                  status: "error",
                  error: getErrorResponseText(error),
                });
              });
          })
          .catch((error) => {
            processUploadError(error, creative.id);
            dispatch({
              type: "set-status",
              key: file.key,
              status: "error",
              error: getErrorResponseText(error),
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "set-status",
          key: file.key,
          status: "error",
          error: getErrorResponseText(error),
        });
      });
  };
  const uploadAssets = () => {
    Promise.all(filesToUpload.map(uploadAsset));
  };
  const setVersion = (key, version) => {
    dispatch({ type: "set-version", key, version });
  };
  const setLanguage = (key, language) => {
    dispatch({ type: "set-language", key, language });
  };
  const removeFile = (key) => {
    onFileRemove(key);
    dispatch({ type: "remove-entry", key });
  };
  const retryFailedUploads = () => {
    const failedFileKeys = extraState
      .filter((e) => e.status === "error")
      .map((e) => e.key);
    const failedFiles = filesToUpload.filter((e) =>
      failedFileKeys.includes(e.key)
    );
    setIsRetryInProgress(true);
    Promise.allSettled(failedFiles.map(uploadAsset)).finally(() => {
      setIsRetryInProgress(false);
    });
  };

  const [isDisabled, submitDisabledReason] = isSubmitDisabled(extraState);
  const isInitialState = extraState.every((e) => e.status === "prepare");
  const isUploadingState = extraState.some((e) => e.status === "uploading");
  const isTerminalState = extraState.every(
    (e) => e.status === "success" || e.status === "error"
  );
  const hasFailedUploads = extraState.some((e) => e.status === "error");
  const closeModal = () => {
    const shouldRefreshPage = !isInitialState;
    onClose(shouldRefreshPage);
  };
  const { user } = React.useContext(AuthStateContext);
  const { username } = user;
  const isZephyr = isZephyrEnv();

  return (
    <Modal backdrop="static" onHide={closeModal} show centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload new assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploadingState && (
          <div className="mb-2 ms-3">
            <p className="h6" style={{ color: "red" }}>
              Creatives are being uploaded to the Cactus server.
              <br />
              Please do not close the page until the uploads to Cactus are
              completed.
            </p>
          </div>
        )}
        {isTerminalState && (
          <div className="mb-2 ms-3">
            <p className="h6">
              Uploads to Cactus are completed.
              {hasFailedUploads && "You can retry the failed uploads."}
              <br />
              You can close the page or navigate to the{" "}
              <Link to={`/jobs/?user=${username}`}>Job Center</Link> for network
              uploads statuses.
            </p>
          </div>
        )}
        <Container>
          <Row className="mb-3">
            <Col xs={5} style={{ fontWeight: "bold" }}>
              File:
            </Col>
            {isZephyr && (
              <Col xs={1} style={{ fontWeight: "bold" }}>
                Version:
              </Col>
            )}
            <Col xs={1} style={{ fontWeight: "bold" }}>
              Duration:
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              Resolution:
            </Col>
            <Col xs={2} style={{ fontWeight: "bold" }}>
              Language:
            </Col>
            <Col xs={1} style={{ fontWeight: "bold" }}>
              {isInitialState ? "Remove:" : "Cactus Upload"}
            </Col>
          </Row>
          {filesToUpload.map((fileAdded) => (
            <UploadAssetBlock
              key={fileAdded.key}
              fileAdded={fileAdded}
              languages={languages}
              removeFile={removeFile}
              state={extraState.find((e) => e.key === fileAdded.key)}
              setVersion={setVersion}
              setLanguage={setLanguage}
              showRemoveColumn={isInitialState}
              isZephyr={isZephyr}
            />
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalState && (
          <ReasonTooltip reason={submitDisabledReason}>
            <Button
              variant="primary"
              onClick={uploadAssets}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </ReasonTooltip>
        )}
        {isTerminalState && hasFailedUploads && (
          <Button
            variant="primary"
            onClick={retryFailedUploads}
            disabled={isRetryInProgress}
          >
            Retry all failed uploads
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function UploadAssetBlock({
  fileAdded,
  languages,
  removeFile,
  state,
  setVersion,
  setLanguage,
  showRemoveColumn,
  isZephyr,
}) {
  const defaultLanguage = getDefaultLanguage(fileAdded.file.name, languages);
  const versions = generateVersionsOptions(1, 50);
  const defaultVersion = getDefaultOptionFromFilename(
    fileAdded.file.name,
    versions
  );
  const defaultVersionValue = defaultVersion ? defaultVersion.value : null;
  useEffect(() => {
    setLanguage(state.key, defaultLanguage.value);
    setVersion(state.key, defaultVersionValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage.value, defaultVersionValue]);
  return (
    <Row className="mb-3">
      <Col xs={5} className="break-word">
        {fileAdded.file.name}
      </Col>
      {isZephyr && (
        <Col>
          <DetachedSelect
            defaultValue={defaultVersion}
            options={versions}
            onChange={(value) => setVersion(state.key, value)}
            isError={state.version === null}
          />
        </Col>
      )}
      <Col xs={1} className="pt-1">
        <DelayedValue value={state.duration} />
      </Col>
      <Col xs={2} className="pt-1">
        <DelayedValue value={state.resolution} />
      </Col>
      <Col xs={2}>
        <DetachedSelect
          defaultValue={defaultLanguage}
          options={languages}
          onChange={(value) => setLanguage(state.key, value)}
        />
      </Col>
      {showRemoveColumn && (
        <Col xs={1}>
          <Button
            onClick={() => removeFile(fileAdded.key)}
            type="button"
            variant="outline-primary"
          >
            Remove
          </Button>
        </Col>
      )}
      <Col xs={1}>
        <StatusIcon status={state.status} error={state.error} />
      </Col>
    </Row>
  );
}

function DelayedValue({ value }) {
  if (value) {
    return <span>{value}</span>;
  }
  return (
    <FontAwesomeIcon
      className="fa fa-spin fa-2x"
      icon={faSpinner}
      color="#0d6efd"
    />
  );
}

function isSubmitDisabled(extraState) {
  if (extraState.some((e) => e.status === "uploading")) {
    return [true, "Upload is in progress."];
  }
  if (!extraState.every((e) => e.language)) {
    return [true, "Languages for all assets should be set before uploading."];
  }
  if (!extraState.every((e) => e.resolution && e.duration)) {
    return [
      true,
      "All resolutions and durations should be detected before uploading the assets.",
    ];
  }
  if (isZephyrEnv()) {
    if (!extraState.every((e) => e.version)) {
      return [true, "All versions should be set before uploading."];
    }
  }
  return [false, null];
}

function getInitialExtraState(filesToUpload) {
  return filesToUpload.map((file) => ({
    key: file.key,
    language: "",
    resolution: "",
    duration: null,
    status: "prepare",
    error: null,
  }));
}

function extraStateReducer(state, action) {
  const { type, key, ...payload } = action;
  if (type === "remove-entry") {
    return state.filter((e) => e.key !== key);
  }
  const transform = getTransformationFunction(type);
  return state.map((e) => (e.key === key ? transform(e, payload) : e));
}

function getTransformationFunction(type) {
  if (type === "set-metadata") {
    return (entry, payload) => {
      const { resolution, duration } = payload;
      return { ...entry, resolution, duration };
    };
  }
  if (type === "set-version") {
    return (entry, payload) => {
      const { version } = payload;
      return { ...entry, version };
    };
  }
  if (type === "set-language") {
    return (entry, payload) => {
      const { language } = payload;
      return { ...entry, language };
    };
  }
  if (type === "set-status") {
    return (entry, payload) => ({
      ...entry,
      status: payload.status,
      error: payload.error || null,
    });
  }
  return (entry) => entry;
}

function processUploadError(error, creativeId) {
  if (error.response && error.response.status === 413) {
    internalAxios.post("action-logs/", {
      custom_metadata: {
        messages: [{ text: "File size too large", type: "error" }],
      },
      creative_concept: creativeId,
      action_type: "creative_upload",
    });
  }
}

export { UploadAssetsModal };
