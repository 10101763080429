import {
  faFacebookSquare,
  faGoogle,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLoaderData, defer, useRevalidator } from "react-router-dom";

import { useAlert } from "~/base/alerts";
import { showParsingErrorAlerts } from "~/blocks/creatives/agency";
import { filtersLoader } from "~/blocks/creatives/assets/filters";
import {
  DeleteFilesButton,
  SelectAllFilesCheckbox,
} from "~/blocks/delete-files";
import { VideoFilePreview } from "~/blocks/file-preview/file-preview";
import {
  GoogleAdsCredsContainer,
  GoogleAdsCredsGuard,
} from "~/blocks/google-ads/credentials";
import {
  YoutubeCredsContainer,
  YoutubeCredsGuard,
} from "~/blocks/youtube/credentials";
import { GdriveLink, ExternalLink } from "~/components/external-link";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { isZephyrEnv } from "~/constants";
import { axios, extractSearch } from "~/utils";

import { AssetDropzone, GdriveCredsDropzoneGuard } from "./dropzone";
import { UploadAssetsModal } from "./upload-modal";

import "./assets.scss";

function CreativeAssetsPage() {
  return (
    <GoogleAdsCredsContainer>
      <GoogleAdsCredsGuard>
        <YoutubeCredsContainer>
          <YoutubeCredsGuard>
            <CreativeAssetsFunctionPage />
          </YoutubeCredsGuard>
        </YoutubeCredsContainer>
      </GoogleAdsCredsGuard>
    </GoogleAdsCredsContainer>
  );
}

function CreativeAssetsFunctionPage() {
  const { creativeAssets, creative, languages } = useLoaderData();
  const revalidator = useRevalidator();

  const refreshPage = () => {
    revalidator.revalidate();
  };
  const isZephyr = isZephyrEnv();
  const DescriptionComponent = isZephyr
    ? ZephyrCreativeDescriptionBlock
    : CreativeDescriptionBlock;

  return (
    <>
      <PageHeader />
      <Row className="mb-4 pt-4">
        {creative.preview && (
          <Col xs={1}>
            <VideoFilePreview
              preview={creative.preview}
              thumbnailWidth="100px"
              thumbnailHeight="150px"
            />
          </Col>
        )}
        <Col xs={7}>
          <DescriptionComponent creative={creative} />
        </Col>
        <Col>
          <GdriveCredsDropzoneGuard>
            <AssetDropzone>
              {({ filesToUpload, removeFile, clearFiles }) =>
                !!filesToUpload.length && (
                  <UploadAssetsModal
                    filesToUpload={filesToUpload}
                    onFileRemove={removeFile}
                    onClose={(shouldRefreshPage) => {
                      clearFiles();
                      if (shouldRefreshPage) {
                        refreshPage();
                      }
                    }}
                    creative={creative}
                    languages={languages}
                  />
                )
              }
            </AssetDropzone>
          </GdriveCredsDropzoneGuard>
        </Col>
      </Row>
      <CreativeAssetsTable
        data={creativeAssets}
        concept={creative}
        refreshPage={refreshPage}
        isZephyr={isZephyr}
      />
    </>
  );
}

CreativeAssetsPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const [conceptsResponse] = await Promise.all([
    axios(`creatives/concept/${params.conceptID}${search}`),
  ]);
  const [creativeResponse] = await Promise.all([
    axios(`creative-concepts/${params.conceptID}`),
  ]);
  const [languagesResponse] = await Promise.all([axios("languages/")]);
  const filtersPromise = filtersLoader();

  return defer({
    creativeAssets: conceptsResponse.data,
    creative: creativeResponse.data,
    languages: languagesResponse.data,
    filtersPromise,
  });
};

const uploadIcons = {
  google_ads: (
    <FontAwesomeIcon
      className="fa fa-google fa-2x"
      icon={faGoogle}
      color="#DB4437"
    />
  ),
  facebook: (
    <FontAwesomeIcon
      className="fa fa-facebook-square fa-2x"
      icon={faFacebookSquare}
      color="#3b5998"
    />
  ),
  youtube: (
    <FontAwesomeIcon
      className="fa fa-youtube fa-2x"
      icon={faYoutube}
      color="#FF0000"
    />
  ),
};

function NetworkUploadedSign({ network, videoId }) {
  const tooltip = (
    <Tooltip className="info-tooltip">
      <div>
        <p>Video id: {videoId}</p>
      </div>
    </Tooltip>
  );
  return (
    <OverlayTrigger
      delay={{ show: 400, hide: 400 }}
      overlay={tooltip}
      transition={false}
    >
      <span className="mx-1">{uploadIcons[network.toLowerCase()]}</span>
    </OverlayTrigger>
  );
}

function getAgencyName(agency) {
  return agency === null ? "-" : agency;
}

function CreativeAssetsTable({ data, concept, refreshPage, isZephyr }) {
  const [selectedAssets, setSelectedAssets] = React.useState([]);
  const toggleRowSelection = (id) => {
    if (selectedAssets.includes(id)) {
      setSelectedAssets(selectedAssets.filter((rowId) => rowId !== id));
    } else {
      setSelectedAssets([...selectedAssets, id]);
    }
  };
  const addAlert = useAlert();

  const syncCreativeConcept = () =>
    axios({
      method: "POST",
      url: `concepts/${concept.id}/sync`,
    })
      .then((response) => {
        if (response.data.errors.length > 0) {
          showParsingErrorAlerts(response.data.errors, addAlert);
        }
        if (response.data.missing_creatives.length > 0) {
          const missingCreatives = response.data.missing_creatives;
          missingCreatives.map((creative) =>
            addAlert(
              `Creative asset ${creative.long_name} doesn't exist in Gdrive folder`,
              "warning",
              null
            )
          );
        }
        if (response.data.name_mismatch_creatives.length > 0) {
          const nameMismatch = response.data.name_mismatch_creatives;
          nameMismatch.map((creative) =>
            addAlert(
              `Name of creative ${creative.long_name} does not match Gdrive file name`,
              "warning",
              null
            )
          );
        }
        addAlert("Synced successfully", "info");
        refreshPage();
      })
      .catch((error) => {
        const responseData = error.response.data;
        if (!error.response) {
          addAlert(`Error: ${error.message}`, "error", null);
          return;
        }
        if (responseData.errors) {
          showParsingErrorAlerts(responseData.errors, addAlert);
        } else {
          const message =
            responseData.detail !== null ? responseData.detail : responseData;
          addAlert(
            `Failed to sync agency creative: ${JSON.stringify(message)}`,
            "error",
            null
          );
        }
      });

  function isUploadInProgress(assetIds) {
    const assets = data.results.filter((a) => assetIds.includes(a.id));
    return assets.some((a) => a.is_upload_in_progress);
  }

  const TableComponent = isZephyr ? ZephyrAssetsTable : AssetsTable;

  return (
    <>
      <DeleteFilesButton
        endpoint="creatives"
        files={selectedAssets}
        refreshPage={refreshPage}
        disableAction={isUploadInProgress(selectedAssets)}
      />
      {concept.link_uploaded && (
        <Button
          variant="primary"
          className="mb-2 mx-2"
          onClick={syncCreativeConcept}
        >
          Sync
        </Button>
      )}
      <TableComponent
        data={data}
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        toggleRowSelection={toggleRowSelection}
      />
      <Pagination data={data} />
    </>
  );
}

function ZephyrAssetsTable({
  data,
  selectedAssets,
  setSelectedAssets,
  toggleRowSelection,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">
            <SelectAllFilesCheckbox
              selectedFiles={selectedAssets}
              setSelectedFiles={setSelectedAssets}
              allFiles={data.results}
            />
          </th>
          <th scope="col" style={{ width: "4em" }}>
            ID
          </th>
          <th scope="col">Time</th>
          <th scope="col">Uploaded by</th>
          <th scope="col">Creative Name</th>
          <th scope="col">Version</th>
          <th scope="col">Duration</th>
          <th scope="col">Resolution</th>
          <th scope="col">Language</th>
          <th scope="col">Google Drive Link</th>
          <th scope="col">Creative Uploaded To</th>
        </tr>
      </thead>
      <tbody>
        {data.results.map((creativeAsset) => (
          <tr
            key={creativeAsset.id}
            style={{ height: "4em", verticalAlign: "middle" }}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedAssets.includes(creativeAsset.id)}
                onChange={() => toggleRowSelection(creativeAsset.id)}
              />
            </td>
            <td>{creativeAsset.id}</td>
            <td>
              {moment
                .utc(creativeAsset.created_at)
                .format("MMMM D, YYYY HH:mm:ss")}
            </td>
            <td>{creativeAsset.author_name}</td>
            <td>{creativeAsset.long_name}</td>
            <td>{creativeAsset.version}</td>
            <td>{creativeAsset.duration}</td>
            <td>{creativeAsset.resolution}</td>
            <td>{creativeAsset.language_field}</td>
            <td>
              <GdriveLink link={creativeAsset.creative_link} />
            </td>
            <td>
              {creativeAsset.network_uploads.map((upload) => (
                <NetworkUploadedSign
                  key={upload.id}
                  network={upload.network}
                  videoId={upload.network_asset_id}
                />
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function AssetsTable({
  data,
  selectedAssets,
  setSelectedAssets,
  toggleRowSelection,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">
            <SelectAllFilesCheckbox
              selectedFiles={selectedAssets}
              setSelectedFiles={setSelectedAssets}
              allFiles={data.results}
            />
          </th>
          <th scope="col" style={{ width: "4em" }}>
            ID
          </th>
          <th scope="col">Time</th>
          <th scope="col">Long Name</th>
          <th scope="col">Duration</th>
          <th scope="col">Resolution</th>
          <th scope="col">Language</th>
          <th scope="col">Agency</th>
          <th scope="col">Google Drive Link</th>
          <th scope="col">Creative Uploaded To</th>
        </tr>
      </thead>
      <tbody>
        {data.results.map((creativeAsset) => (
          <tr
            key={creativeAsset.id}
            style={{ height: "4em", verticalAlign: "middle" }}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedAssets.includes(creativeAsset.id)}
                onChange={() => toggleRowSelection(creativeAsset.id)}
              />
            </td>
            <td>{creativeAsset.id}</td>
            <td>
              {moment
                .utc(creativeAsset.created_at)
                .format("MMMM D, YYYY HH:mm:ss")}
            </td>
            <td>{creativeAsset.long_name}</td>
            <td>{creativeAsset.duration}</td>
            <td>{creativeAsset.resolution}</td>
            <td>{creativeAsset.language_field}</td>
            <td>{getAgencyName(creativeAsset.agency)}</td>
            <td>
              <GdriveLink link={creativeAsset.creative_link} />
            </td>
            <td>
              {creativeAsset.network_uploads.map((upload) => (
                <NetworkUploadedSign
                  key={upload.id}
                  network={upload.network}
                  videoId={upload.network_asset_id}
                />
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function CreativeDescriptionBlock({ creative }) {
  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>App</Col>
            <Col style={{ fontWeight: "bold" }}>Concept #</Col>
            <Col style={{ fontWeight: "bold" }}>Concept Name</Col>
            <Col style={{ fontWeight: "bold" }}>Intro</Col>
            <Col style={{ fontWeight: "bold" }}>Asana Ticket</Col>
          </Row>
          <Row>
            <Col>{creative.application_code}</Col>
            <Col>{creative.creative_number}</Col>
            <Col>{creative.concept_name}</Col>
            <Col>{creative.intro}</Col>
            <Col>
              <ExternalLink link={creative.asana_link} text="Link" />
            </Col>
          </Row>
        </Row>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>Creative Type</Col>
            <Col style={{ fontWeight: "bold" }}>Identifier</Col>
            <Col style={{ fontWeight: "bold" }}>Agency</Col>
            <Col style={{ fontWeight: "bold" }}>UA Folder</Col>
            <Col style={{ fontWeight: "bold" }}>Creative Project Folder</Col>
          </Row>
          <Row>
            <Col>{creative.creative_type_name}</Col>
            <Col>{creative.identifier}</Col>
            <Col>{getAgencyName(creative.agency)}</Col>
            <Col>
              <GdriveLink link={creative.creative_folder_link} />
            </Col>
            <Col>
              <GdriveLink link={creative.creative_team_folder_link} />
            </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

function ZephyrCreativeDescriptionBlock({ creative }) {
  return (
    <Card>
      <Card.Body>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>App</Col>
            <Col style={{ fontWeight: "bold" }}>Concept #</Col>
            <Col style={{ fontWeight: "bold" }}>Name</Col>
            <Col style={{ fontWeight: "bold" }}>Asana Ticket</Col>
          </Row>
          <Row>
            <Col>{creative.application_code}</Col>
            <Col>{creative.creative_number}</Col>
            <Col>{creative.concept_name}</Col>
            <Col>
              <ExternalLink link={creative.asana_link} text="Link" />
            </Col>
          </Row>
        </Row>
        <Row className="mb-3">
          <Row>
            <Col style={{ fontWeight: "bold" }}>2D / 3D</Col>
            <Col style={{ fontWeight: "bold" }}>Type</Col>
            <Col style={{ fontWeight: "bold" }}>Drive Folder Folder</Col>
            <Col />
          </Row>
          <Row>
            <Col>{creative.graphics_type}</Col>
            <Col>{creative.gameplay_type}</Col>
            <Col>
              <GdriveLink link={creative.creative_folder_link} />
            </Col>
            <Col />
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CreativeAssetsPage;
