function toOptions(items) {
  if (Array.isArray(items)) {
    return items.map((v) => ({ value: v, label: v }));
  }
  return Object.entries(items).map(([k, v]) => ({ value: k, label: v }));
}

function findByValue(items, val) {
  if (val == null) return val;
  return items.find(({ value }) => value === val);
}

function ensureList(value) {
  if (Array.isArray(value)) return value;
  return value ? [value] : [];
}

function mapToOptions(applications, valueKey = "id", labelKey = "code") {
  return applications.map((application) => ({
    label: application[labelKey],
    value: application[valueKey],
  }));
}

export { toOptions, findByValue, ensureList, mapToOptions };
