function GdriveLink({ link }) {
  return ExternalLink({ link, text: "Drive Link" });
}

function ExternalLink({ link, text }) {
  if (!link) {
    return "-";
  }
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
}
export { GdriveLink, ExternalLink };
