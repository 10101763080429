import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./index.scss";

function ThumbnailWithPlayButton({ thumbnailLink, onClick, width, height }) {
  return (
    <div
      className="thumbnail-wrapper"
      onClick={onClick}
      style={{ width, height }}
    >
      <img
        src={thumbnailLink}
        alt="Preview"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <span className="play-button">&#9658;</span>{" "}
      {/* Unicode character for play button */}
    </div>
  );
}

function VideoFilePreview({ preview, thumbnailWidth, thumbnailHeight }) {
  const [show, setShow] = React.useState(false);

  const togglePreview = () => {
    setShow(!show);
  };
  if (!preview || !preview.file_url || !preview.file_type) {
    return "";
  }

  return (
    <div className="file-item">
      <ThumbnailWithPlayButton
        thumbnailLink={preview.thumbnail_url}
        onClick={togglePreview}
        width={thumbnailWidth}
        height={thumbnailHeight}
      />
      <FilePreviewModal
        show={show}
        onClose={togglePreview}
        fileName={preview.name}
        fileLink={preview.file_url}
        fileType={preview.file_type}
      />
    </div>
  );
}

function PreviewableText({ preview, text }) {
  const [show, setShow] = React.useState(false);

  const togglePreview = () => {
    setShow(!show);
  };
  if (!preview || !preview.file_url || !preview.file_type) {
    return <span>{text}</span>;
  }

  return (
    <div>
      <span
        style={{ color: "#1890ff", cursor: "pointer" }}
        onClick={togglePreview}
      >
        {text}
      </span>
      {preview && (
        <FilePreviewModal
          show={show}
          onClose={togglePreview}
          fileName={preview.name}
          fileLink={preview.file_url}
        />
      )}
    </div>
  );
}

function FilePreviewModal({ show, fileLink, fileName, onClose }) {
  const [isLandscape, setIsLandscape] = React.useState(false);
  const toggleOrientation = () => {
    setIsLandscape((prev) => !prev);
  };

  function getModalSize() {
    const deviceDimensions = {
      // below are sizes applovin preview tool uses.
      landscape: { width: 505, height: 305 },
      portrait: { width: 305, height: 505 },
    };

    const { width, height } = isLandscape
      ? deviceDimensions.landscape
      : deviceDimensions.portrait;

    return [width, height];
  }
  const [width, height] = getModalSize();
  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="preview-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{fileName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <iframe
          src={fileLink}
          title={fileName}
          width={width}
          height={height}
          allowFullScreen
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={toggleOrientation}>
          Rotate
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { VideoFilePreview, PreviewableText };
