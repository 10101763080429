import MultiSelect from "@kenshooui/react-multi-select";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAlert } from "~/base/alerts";
import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios, snakeToCamel } from "~/utils";

import { CreativePackType, AssetInputs } from "./utils";

import "./index.scss";

function formRequestData(values, campaigns) {
  return campaigns.map((item) => ({
    application: values.application,
    language: values.language,
    portrait_video_asset: values.portraitVideoAsset,
    landscape_video_asset: values.landscapeVideoAsset,
    end_card: values.endCard,
    playable: values.playable,
    campaign_id: item.id,
    campaign_name: item.label,
  }));
}

function ApplovinLaunchNetworkForm({ options, isZephyr }) {
  const navigate = useNavigate();
  const [campaignOptions, setCampaignsOptions] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const addAlert = useAlert();

  const onSubmit = (values, { setFieldError }) => {
    if (selectedCampaigns.length === 0) {
      addAlert("Please select at least 1 campaign", "error");
      return;
    }
    axios({
      method: "POST",
      url: "applovin-launches/",
      data: formRequestData(values, selectedCampaigns),
    })
      .then(() => {
        navigate("/network-launches");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const backendErrors = error.response.data;
          if (backendErrors[0].non_field_errors) {
            addAlert(backendErrors[0].non_field_errors[0], "error");
          } else {
            Object.keys(backendErrors[0]).forEach((field) => {
              setFieldError(snakeToCamel(field), backendErrors[0][field]);
            });
          }
        } else {
          addAlert(
            "Unexpected error happened on form submission. Please try again or reach out to the MAD Products Team",
            "error"
          );
        }
      });
  };

  const validationSchema = Yup.object().shape({
    application: Yup.string().required("Please specify Application"),
    creativePackType: Yup.string().required(
      "Please specify Creative Pack Type"
    ),
    landscapeVideoAsset: Yup.number().nullable().notRequired(),
    portraitVideoAsset: Yup.number().nullable().notRequired(),
    endCard: Yup.number().when(["creativePackType"], {
      is: CreativePackType.APPLOVIN_VIDEO_ENDCARD,
      then: Yup.number().required("Please specify End card"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    playable: Yup.number().when(["creativePackType"], {
      is: CreativePackType.APPLOVIN_VIDEO_ENDCARD,
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().required("Please specify Playable"),
    }),
    endCard2: Yup.number().nullable().notRequired(),
  });
  const creativePackTypes = [
    { label: "Video & Playable", value: CreativePackType.VIDEO_PLAYABLE },
    { label: "Playable", value: CreativePackType.PLAYABLE },
  ];
  if (!isZephyr) {
    creativePackTypes.splice(0, 0, {
      label: "Video & End Card",
      value: CreativePackType.APPLOVIN_VIDEO_ENDCARD,
    });
  }

  return (
    <Form
      form={false}
      initialValues={{
        application: null,
        language: null,
        creativePackType: null,
        portraitVideoAsset: null,
        landscapeVideoAsset: null,
        endCard2: null,
        endCard: null,
        playable: null,
      }}
      validationSchema={validationSchema}
      syncSubmit
      onSubmit={onSubmit}
    >
      <Row className="mb-3 pb-3">
        <Col xs={2}>Application:*</Col>
        <Col xs={5}>
          <Form.Select name="application" options={options.applications} />
          <Form.Control.ErrorFeedback name="application" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Language:*</Col>
        <Col xs={5}>
          <Form.Select name="language" options={options.languages} />
          <Form.Control.ErrorFeedback name="language" />
        </Col>
      </Row>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Creative pack type:*</Col>
        <Col xs={5}>
          <Form.Select name="creativePackType" options={creativePackTypes} />
          <Form.Control.ErrorFeedback name="creativePackType" />
        </Col>
      </Row>
      <AssetInputs
        isZephyr={isZephyr}
        optionsEndpoint="applovin-launches/asset-options"
      />
      <CampaignSelector
        campaignOptions={campaignOptions}
        setCampaignsOptions={setCampaignsOptions}
        selectedCampaigns={selectedCampaigns}
        setSelectedCampaigns={setSelectedCampaigns}
      />
      <Row>
        <Col xs="auto">
          <SubmitButton className="px-4" icon={false} title="Launch" />
        </Col>
      </Row>
    </Form>
  );
}

function campaignsToOptions(campaigns) {
  return campaigns.map((campaign) => ({
    id: campaign.id,
    label: campaign.name,
  }));
}

function CampaignSelector({
  campaignOptions,
  setCampaignsOptions,
  selectedCampaigns,
  setSelectedCampaigns,
}) {
  const {
    values: { application },
  } = useFormikContext();
  const addAlert = useAlert();

  useEffect(() => {
    axios({
      method: "GET",
      url: "network-campaings/?network=Applovin",
    })
      .then((response) => {
        const options = campaignsToOptions(response.data);
        setCampaignsOptions(options);
      })
      .catch(() => {
        addAlert(
          "Error on loading campaigns. Please try again or reach out to the MAD Products Team",
          "error"
        );
      });
  }, [setCampaignsOptions, addAlert]);
  return (
    <div className={classNames({ disabled: application === null })}>
      <Row className="mb-3 pb-3">
        <Col xs={2}>Campaigns:</Col>
        <Col xs={5}>
          <MultiSelect
            items={campaignOptions}
            itemHeight={40}
            selectAllHeight={40}
            selectedItems={selectedCampaigns}
            onChange={setSelectedCampaigns}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ApplovinLaunchNetworkForm;
