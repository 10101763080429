import moment from "moment/moment";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useLoaderData, defer } from "react-router-dom";

import {
  filtersLoader,
  PageFiltersForm,
} from "~/blocks/playables/concepts/filters";
import { GdriveLink, ExternalLink } from "~/components/external-link";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";

function PlayableConceptsPage() {
  const { endCards, filtersPromise } = useLoaderData();

  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
        filterAlwaysOpened
      >
        <Button variant="primary" href="/playables/concepts/new">
          Add New Playable
        </Button>
      </PageHeader>
      <PlayableConceptsTable data={endCards} />
    </>
  );
}

PlayableConceptsPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const response = await axios(`playable-concepts${search}`);
  const filtersPromise = filtersLoader();

  return defer({
    endCards: response.data,
    filtersPromise,
  });
};

function PlayableConceptsTable({ data }) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th scope="col" style={{ width: "4em" }}>
              ID
            </th>
            <th scope="col">User</th>
            <th scope="col" style={{ width: "15em" }}>
              Date
            </th>
            <th scope="col" style={{ width: "5em" }}>
              App
            </th>
            <th scope="col">Playable#</th>
            <th scope="col">Theme</th>
            <th scope="col">Asana Ticket</th>
            <th scope="col">Playable Folder Link</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((concept) => (
            <tr key={concept.id}>
              <td>{concept.id}</td>
              <td>{concept.created_by_name}</td>
              <td>
                {moment.utc(concept.created_at).format("DD.MM.YYYY, HH:mm:ss")}
              </td>
              <td>{concept.application_code}</td>
              <td>{concept.creative_number}</td>
              <td>{concept.theme}</td>
              <td>
                <ExternalLink link={concept.asana_link} text="Link" />
              </td>
              <td>
                <GdriveLink link={concept.creative_folder_link} />
              </td>
              <td>
                <Link to={`/playables/concepts/${concept.id}/assets`}>
                  <Button variant="outline-primary" className="mb-1 me-3">
                    View
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={data} />
    </>
  );
}

export default PlayableConceptsPage;
